import axios from "axios";
import * as crypto from "crypto-browserify";
import { saveAs } from "file-saver";
import { Buffer } from "buffer";
import { forEach, get, isEmpty, isString, orderBy } from "lodash";
import { cookieKeysEnum, CountryCodes, disableViewSubModulePrivilegeId } from "models/common.models";
import moment from "moment";
import React from "react";
import ReactDOMServer from "react-dom/server";
import Cookies from "universal-cookie";
import XlsxPopulate from "xlsx-populate";
import { SEARCH_COMMITTEES, SEARCH_MEMBERS, SEARCH_ORGANIZATIONS, SEARCH_TASK, SEARCH_FEEGROUP } from "../action-types";
import { setCurrentLocationStorage } from "../common.actions";
import { MODULES, NAVIGATION, NAVIGATIONHEADER, PAGE_CONFIG } from "../models/navigation.models";
export const Blank_Space_Check_Regex = /^(?=.*\S).+$/;
export const Name_Regex = /^[A-Za-z]*$/;
export const NameWithWhiteSpace_Regex = /^[a-zA-Z ]*$/;
export const AlphaNumeric_Regex = /^[A-Za-z0-9]*$/;
export const Number_Regex = /^[0-9]*$/;
export const AlphaNumericWhiteSpace_Regex = /^[a-zA-Z0-9 ]+$/;
export const AlphaNumericHypen_Regex = /^[0-9\-]+$/;
export const Email_Regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
export const NegativeRegex = /^-?\d+(\.\d{1,2})?$/;
export const PositiveRegex = /^\d+(\.\d{1,2})?$/;
export const Password_Regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./\\:;<=>?@[\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./\\:;<=>?@[\]^_`{|}~]{8,}$/i;
export const File_Regex = /([a-zA-Z0-9\s_\\.\-:()])+(.pdf)$/;
export const IP_Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const PhoneNumber_Regex = /^[0-9 +\-()]*$/;
export const MinDate = "01/01/1900";
export const minDateforReport = "01/01/2017";
export const COOKIES = new Cookies();
export const SPECIAL_CHARACTER_Regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
export const zipCodeRegex = /^[a-z0-9]{0,9}$/i;
export const zipCodeUSRegex = /^[0-9]{5}$/;
export const zipCodeCARegex = /^[a-z]\d[a-z][ ]\d[a-z]\d$/i;

const securityKey = "aes-256-cbc";

export const encrypt = (text) => {
  try {
    if (text) {
      text = text.toString();
      let iv = crypto.randomBytes(Number(process.env.REACT_APP_STORAGE_IV_LENGTH));
      let cipher = crypto.createCipheriv(securityKey, Buffer.from(process.env.REACT_APP_STORAGE_VALUE), iv);
      let encrypted = cipher.update(text);
      encrypted = Buffer.concat([encrypted, cipher.final()]);
      return iv.toString("hex") + ":" + encrypted.toString("hex");
    }
    return text;
  } catch (e) {
    console.log("Exception in Encryption", e);
  }
};

export const decrypt = (text) => {
  try {
    if (text) {
      text = text.toString();
      let textParts = text.split(":");
      if (textParts && textParts.length > 0) {
        let iv = Buffer.from(textParts.shift(), "hex");
        let encryptedText = Buffer.from(textParts.join(":"), "hex");
        let decipher = crypto.createDecipheriv(securityKey, Buffer.from(process.env.REACT_APP_STORAGE_VALUE), iv);
        let decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);
        return decrypted.toString();
      }
    }
    return text;
  } catch (e) {
    console.log("Exception in Decryption", e);
  }
};

export const cacheManager = {
  getItem: (key, isDecrypt = true) => {
    if (isDecrypt) {
      return decrypt(window.localStorage.getItem(key));
    } else {
      return window.localStorage.getItem(key);
    }
  },
  setItem: (key, value) => {
    let string = isString(value) ? encrypt(value) : encrypt(JSON.stringify(value));
    window.localStorage.setItem(key, string);
  },
  removeItem: (key) => {
    window.localStorage.removeItem(key);
  },
  clearSession: () => {
    window.localStorage.clear();
  },
};

export const cookiesManager = {
  setCookie: (key, value, isParentOrigin = true) => {
    let _date = new Date();
    _date.setTime(_date.getTime() + 60 * 60 * 24 * 1000); // TODO : Remove Cookies in 24Hrs.
    let options = { path: "/", expires: _date, domain: isParentOrigin ? getDomainFromSubDomain(window.location.hostname) : window.location.hostname, secure: window.location.hostname === "localhost" ? false : true };
    COOKIES.set(key, encrypt(value), options);
  },
  getCookie: (key, isDecrypt = true, isParentOrigin = true) => {
    let options = { path: "/", domain: isParentOrigin ? getDomainFromSubDomain(window.location.hostname) : window.location.hostname, secure: window.location.hostname === "localhost" ? false : true };
    if (isDecrypt) {
      return decrypt(COOKIES.get(key, options));
    } else {
      return COOKIES.get(key, options);
    }
  },
  removeCookie: (key, isParentOrigin = true) => {
    let options = { path: "/", domain: isParentOrigin ? getDomainFromSubDomain(window.location.hostname) : window.location.hostname, secure: window.location.hostname === "localhost" ? false : true };
    COOKIES.remove(key, options);
  },
};

export const getLoggedInUserId = () => {
  const userDetails = cacheManager.getItem("userDetail");
  if (userDetails) {
    let jsonDetails = JSON.parse(userDetails);
    return jsonDetails ? get(jsonDetails, "userId") : 0;
  }
  return 0;
};

export const getDateValue = (dateObj) => {
  let date = new Date(dateObj);

  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export function utcDateTimeToLocalDateTime(date) {
  return moment.utc(date).local().format("MM/DD/YYYY hh:mm A");
}

export function utcDateTimeSecondToLocalDateTime(date) {
  return moment.utc(date).local().format("MM/DD/YYYY hh:mm:ss A");
}

// Change format to MMM dd, yyyy
export function dateFormatToStringWithDateAndTime(date) {
  date = utcDateTimeToLocalDateTime(date);
  return moment(date).format("ll") + " at " + moment(date).format("LT");
}

export const getCookie = (key) => {
  let name = key + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export function logMessage(key, value) {
  console.info(key + " =>", value);
}
export const anchorTextLengthValidate = (ref, lenCount = 35) => {
  let parentNode = get(ref, "parentElement");
  if (parentNode) {
    let parentSelector = get(parentNode, "parentElement");
    if (parentSelector && ref) {
      ref.removeAttribute("style");
      if (parentSelector.offsetWidth >= ref.offsetWidth) {
        ref.setAttribute("style", "width:auto");
      } else {
        ref.setAttribute("style", "width:" + (parentSelector.offsetWidth - 10) + "px");
      }
    }
  }
};

export function nullCheck(value) {
  return value && value !== "" && value !== undefined && value !== false;
}

// Masking for mm/yyyy
export const monthYearDateMask = (val, prevVal) => {
  // Prevent non-digit characters being entered
  if (isNaN(parseInt(val[val.length - 1], 10))) {
    return val.slice(0, -1);
  }

  // When user is deleting, this prevents immediate re-addition of '/' when it's deleted
  if (prevVal && prevVal.length >= val.length) {
    return val;
  }

  // Add / at appropriate sections of the input
  if (val.length === 2) {
    val += "/";
    return val;
  }

  // Add / at appropriate sections of the input
  if (prevVal && prevVal.length === 2 && val.length === 3) {
    val = val.substring(0, 2) + "/" + val.substring(2);
    return val;
  }

  // Prevent characters being entered after Dob is full
  if (val.length >= 7) {
    return val.slice(0, 7);
  }

  return val;
};

// Masking for date mm/dd/yyyy
export const dateMasking = (val, prevVal) => {
  // Prevent non-digit characters being entered
  if (isNaN(parseInt(val[val.length - 1], 10))) {
    return val.slice(0, -1);
  }

  // When user is deleting, this prevents immediate re-addition of '/' when it's deleted
  if (prevVal && prevVal.length >= val.length) {
    return val;
  }

  // Add / at appropriate sections of the input
  if (val.length === 2 || val.length === 5) {
    val += "/";
    return val;
  }

  // Add / at appropriate sections of the input
  if (prevVal && prevVal.length === 2 && val.length === 3) {
    val = val.substring(0, 2) + "/" + val.substring(2);
    return val;
  }

  // Add / at appropriate sections of the input
  if (prevVal && prevVal.length === 5 && val.length === 6) {
    val = val.substring(0, 5) + "/" + val.substring(5);
    return val;
  }

  // Prevent characters being entered after Dob is full
  if (val.length >= 10) {
    return val.slice(0, 10);
  }

  return val;
};

export const phoneNumberMask = (input) => {
  if (input) {
    try {
      let number = [];
      if (input.length === 10) {
        number = input.match(/(\d{3})(\d{3})(\d{4})/);

        return number[1] + "-" + number[2] + "-" + number[3];
      } else if (input.length > 10 && input.length < 14) {
        if (input.length === 11) {
          number = input.match(/(\d{1})(\d{3})(\d{3})(\d{4})/);
        } else if (input.length === 12) {
          number = input.match(/(\d{2})(\d{3})(\d{3})(\d{4})/);
        } else if (input.length === 13) {
          number = input.match(/(\d{3})(\d{3})(\d{3})(\d{4})/);
        }

        return "(+" + number[1] + ") " + number[2] + "-" + number[3] + "-" + number[4];
      }
    } catch (err) {
      // In case data is wrong, then handle the exception and return the original value
      return input;
    }
  }

  return input;
};

export const toDateWithTime = (date) => {
  return moment(date).format("MM/DD/YYYY hh:mm A");
};

export const formatDateToMMDDYYYYHHMMSS = (date) => {
  return moment.utc(date).local().format("MM/DD/YYYY HH:mm:ss");
};

export const toDateWithTimeLocal = (date) => {
  return moment.utc(date).local().format("MM/DD/YYYY hh:mm A");
};

export const toUTCDateAndTime = (date, format) => {
  return moment.utc(moment(date)).format(format);
};

export const exportFileName = (fileName, date) => {
  if (date) {
    return `${fileName}_` + moment(date).format("MM-DD-YYYY");
  }
  return `${fileName}_` + moment(new Date()).format("MM-DD-YYYY");
};

export const exportFileNameWithDot = (fileName, date) => {
  if (date) {
    return `${fileName}_` + moment(date).format("MM.DD.YYYY");
  }
  return `${fileName}_` + moment(new Date()).format("MM.DD.YYYY");
};

export const getUrlToRedirect = (currentSearchOption, { PAGE_TYPE, USER_PRIVILEGE }) => {
  switch (currentSearchOption) {
    case SEARCH_FEEGROUP:
      return routeNavigationUrl(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW);
    case SEARCH_TASK:
      return routeNavigationUrl(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW);
    case SEARCH_MEMBERS:
      return routeNavigationUrl(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW);
    case SEARCH_ORGANIZATIONS:
      return routeNavigationUrl(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.VIEW);
    case SEARCH_COMMITTEES:
      return routeNavigationUrl(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.VIEW);
    default:
      return "";
  }
};

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export const getFileNameWithoutType = (fileName) => {
  let lastIndex = fileName.lastIndexOf(".");

  if (fileName.length > 0 && lastIndex > 0) {
    return fileName.substring(0, lastIndex);
  }

  return fileName;
};

export const getFileType = (fileName) => {
  let lastIndex = fileName.lastIndexOf(".");

  if (fileName.length > lastIndex + 1 && lastIndex > 0) {
    return fileName.substring(lastIndex + 1).toLowerCase();
  }

  return fileName;
};

export const getFilteredUsersList = (usersList, usersToExcludeList) => {
  let filteredList = [];

  filteredList = usersList.filter((user) => {
    let index = usersToExcludeList.findIndex((u) => {
      return u.UserId === user.UserId;
    });

    if (index === -1) {
      return true;
    }
    return false;
  });

  return filteredList;
};

/* Pure functions for transforming data. */

export const toMMMMYYYY = (text) => {
  const dateTime = new Date(text);
  return moment(dateTime).format("MMMM  YYYY");
};

export const toWithTime = (text) => {
  return new Date(text).toLocaleDateString("en-US") + ", " + new Date(text).toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
};
export const toCurrency = (n, decimals = "") => {
  if (n === null || n === undefined) {
    n = 0;
  }
  const num = n.toString().split(".");
  const decimal = num[1] ? `.${num[1]}` : decimals;
  return "$  " + num[0].replace(/(\d{1,3})(?=(\d{3})+$)/g, "$1,") + decimal;
};

export const capitalize = (text) => {
  return (
    text
      // Insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // Uppercase the first character
      .replace(/^./, (str) => {
        return str.toUpperCase();
      })
  );
};

export const toGMapQuery = (address) => {
  return `https://www.google.com/maps`;
  // return `https://www.google.com/maps?q='${address.replace(/\s/g, '+')}`
};

export const toUpperMagnitude = (num) => {
  return Math.pow(10, num.toString().length);
};

export const formatDateToYYYYMMDD = (date) => {
  if (date) {
    return moment(date).format("YYYY-MM-DD");
  }

  const now = new Date();
  return moment(now).format("YYYY-MM-DD");
};

export const formatDateToMMDDYYYY = (date, format = "MM/DD/YYYY") => {
  if (date) {
    return moment(date).format(format);
  }

  const now = new Date();
  return moment(now).format(format);
};

export const formatDateToMMYYYY = (date) => {
  return moment(date).format("MM/YYYY");
};

export const formatDateToYYYY = (date) => {
  if (date) {
    return moment(date).format("YYYY");
  }
  const now = new Date();
  return moment(now).format("YYYY");
};

// Get month number by date
export const formatDateToM = (date) => {
  if (date) {
    return moment(date).month();
  }
  const now = new Date();
  return moment(now).month();
};

// Get month name by number
export const formatMonth = (number, format = "MMMM") => {
  return moment(number, "MM").format(format);
};
// Get month number by name
export const formatMonthToNum = (number) => {
  return moment(number, "MMM").format("MM");
};
export const getStartDateOfCurrentMonth = () => {
  const date = new Date();
  return formatDateToMMDDYYYY(new Date(date.getFullYear(), date.getMonth(), 1));
};

export const formatDate = (date, dateFormat = "MM/DD/YYYY") => {
  return moment(date).format(dateFormat);
};

export const getCurrentlyShowingItemsInGrid = (pageNumber, pageSize, totalItems) => {
  if (pageNumber > 0 && pageSize > 0 && totalItems > 0) {
    let startIndex = pageNumber * pageSize - pageSize + 1;
    let lastIndex = totalItems >= pageNumber * pageSize ? pageNumber * pageSize : totalItems;

    return `${startIndex} - ${lastIndex} of ${totalItems} items`;
  }

  return "";
};

export const scrollToTopOfPage = () => {
  let scrollElement = document.getElementById("root");

  if (scrollElement) {
    scrollElement.scrollTop = 0;
  }
};

export const getListItemTextById = (list, id, columnToSelect) => {
  if (list && list.length > 0) {
    const result = list.find((item) => {
      return item.key === id;
    });

    if (result && result[columnToSelect]) {
      return result[columnToSelect];
    }
  }

  return "";
};

export const getListItemTextByValue = (list, value, columnToSelect) => {
  if (list && list.length > 0) {
    const result = list.find((item) => {
      return value && item.value === value.toLowerCase();
    });

    if (result && result[columnToSelect]) {
      return result[columnToSelect];
    }
  }

  return "";
};

export const copyDataToClipboard = (data) => {
  let isCopiedSuccess = false;

  if (window.clipboardData) {
    window.clipboardData.setData("Text", data);
    isCopiedSuccess = true;
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = data;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      isCopiedSuccess = !!successful;
    } catch (err) {
      isCopiedSuccess = false;
    }

    document.body.removeChild(textArea);
  }

  return isCopiedSuccess;
};

export const checkIfUserDidAdvanceSearch = (query) => {
  if (query.indexOf("isAdvanceSearch=true") >= 0) {
    return true;
  }

  return false;
};

export const replaceUrlParam = (url, paramName, paramValue) => {
  if (paramValue === null) {
    paramValue = "";
  }
  let pattern = new RegExp("\\b(" + paramName + "=).*?(&|#|$)");
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, "$1" + paramValue + "$2");
  }
  url = url.replace(/[?#]$/, "");
  return url + (url.indexOf("?") > 0 ? "&" : "?") + paramName + "=" + paramValue;
};

// Extract a query string value from the url
export const extractValueFromUrl = (url, paramName) => {
  let regex = "";
  if (url && url.indexOf(paramName) === 0) {
    regex = new RegExp(paramName + "=([^&#]*)", "i");
  } else {
    regex = new RegExp("[?&]" + paramName + "=([^&#]*)", "i");
  }
  let string = regex.exec(url);
  return string ? string[1] : null;
};

export const formatUserListForCommunicationLog = (list) => {
  let result = [];
  if (list && list.length > 0) {
    list.map((res) => {
      result.push({ key: res.UserName, value: res.UserName, text: res.UserName, UserName: res.UserName, UserId: res.UserId });
    });
  }
  return result;
};

export const extractUserIdsFromComment = (comment) => {
  let userList = [];
  const element = document.createElement("div");
  element.innerHTML = comment;
  const anchorTags = element.getElementsByTagName("a");

  for (let i = 0; i < anchorTags.length; i++) {
    let userId = anchorTags[i].getAttribute("taguserid");

    // Remove the logged in user from this list
    if (Number(userId) && Number(userId) !== getLoggedInUserId()) {
      userList.push(userId);
    }
  }

  return userList;
};

export const getMessage = (messageList, message) => {
  return get(messageList, message);
};

export const loadScriptTag = (script, tagName) => {
  return new Promise((resolve, reject) => {
    if (script !== null && script.length > 0) {
      script.map((src, index) => {
        let body = document.getElementsByTagName(tagName)[0],
          tag = document.createElement("script");

        tag.type = "text/javascript";
        tag.async = false; // Load in order
        const handleLoad = (evt) => {
          resolve(src);
        };
        const handleReject = (evt) => {
          reject(src);
        };

        tag.addEventListener("load", handleLoad);
        tag.addEventListener("error", handleReject);
        tag.src = `${src}?v=${process.env.BUILD_ID}`;
        tag.id = "docx_" + index;
        body.appendChild(tag);
        return tag;
      });
    }
  });
};

export const removeScript = () => {
  // Remove script element while not in used
  for (let i = 0; i < 3; i++) {
    let selector = document.getElementById("docx_" + i);
    if (selector) {
      selector.parentElement.removeChild(selector);
    }
  }
};

export const trimRichText = (richText) => {
  let divElement = document.createElement("div");
  divElement.innerHTML = richText;

  if (divElement.innerText && divElement.innerText.trim().length > 0) {
    return richText;
  }

  return "";
};

export const getHighlightedHTMLElement = (renderElement, highlightText) => {
  const reactElement = getHighlightedReactElement(renderElement, highlightText);

  const htmlString = ReactDOMServer.renderToStaticMarkup(reactElement);

  const textArea = document.createElement("textarea");
  textArea.innerHTML = htmlString;

  return unescape(textArea.value);
};

// If user enters ( or ), then we will not be able to highlight the text
const getHighlightedReactElement = (renderElement, highlightText) => {
  try {
    // Split on higlight term and include term into parts, ignore case
    let parts = renderElement.split(new RegExp(`(${highlightText})`, "gi"));

    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span key={i} style={part.toLowerCase() === highlightText.toLowerCase() ? { fontWeight: "bold" } : {}}>
            {part}
          </span>
        ))}{" "}
      </span>
    );
  } catch (ex) {
    return renderElement;
  }
};

// Function to create data chunks for word export
export const exportWordFile = (docXConfig, data, chunkSize = 3, pageChunkSize = 3) => {
  if (data && data.length > 0) {
    let chunk = createChunkFromArray(data, chunkSize);
    let pageChunk = createChunkFromArray(chunk, pageChunkSize);
    let docx = docXConfig.renderWordTemplate(pageChunk);
    docXConfig.exportFile(docx);
  } else {
    let docx = docXConfig.renderEmptryWordTemplate();
    docXConfig.exportFile(docx);
  }
};

// Get url for export data from existing url by updating the page number and page size
export const getUrlForExportData = (totalItems, isWord) => {
  totalItems = totalItems && Number(totalItems) > 0 ? totalItems : 25;
  let urlString = cacheManager.getItem("exportedParams");
  if (urlString && urlString.length > 0) {
    let paramsList = urlString.split("&");
    paramsList.push("isExport=true");
    if (isWord) paramsList.push("isWord=true");
    for (let i = 0; i < paramsList.length; i++) {
      let keyValuePair = paramsList[i].split("=");

      if (keyValuePair[0] === "pageNumber") {
        keyValuePair[1] = 1;
      } else if (keyValuePair[0] === "pageSize") {
        keyValuePair[1] = totalItems;
      }

      let param = keyValuePair.join("=");
      paramsList[i] = param;
    }

    urlString = paramsList.join("&");
  }

  return urlString;
};

// Function to set custom grid props from the database grid columns response
export const updateGridProps = (gridHeader, tableConfigProps, columnKeys) => {
  if (gridHeader && gridHeader.length > 0) {
    tableConfigProps.excelExportedColumn = [];
    gridHeader.map((res) => {
      tableConfigProps.attributes[res.FieldName] = res.IsSelected;
      tableConfigProps.sortables[res.FieldName] = res.IsSortable;
      tableConfigProps.displayName[res.FieldName] = res.DisplayName;
      if (!res.IsFixedWidth) {
        // If fields is expendables
        tableConfigProps.expandables.push(res.FieldName);
      }

      if (res.IsSelected) {
        // Validate if data will exportable
        tableConfigProps.excelExportedColumn.push({ title: res.DisplayName, fieldsKey: res.FieldName, style: { font: { bold: true } } });
        columnKeys.push(res.ApplicationGridColumnMasterId);
      }
    });
  }
};

// Validate all exportable fields
export const validateExportableFields = (tableHeaderConfig, columns) => {
  let columnsIds = [];
  if (tableHeaderConfig !== null && tableHeaderConfig.length > 0) {
    tableHeaderConfig
      .filter((res) => {
        return columns.indexOf(res.FieldName) > -1;
      })
      .map((filterRes) => {
        columnsIds.push(filterRes.ApplicationGridColumnMasterId);
      });
  }
  return columnsIds.length > 0 ? columnsIds : [];
};

// Function to setup the data set to export the data in excel
// ColumnsDataToReplace : All the columns which need to be replaced with text values
// SourceLists : Lists of object lists to get the data, pass in same order as columnsDataToReplace
export const setupDataForExcelExport = (gridProp, data, columnsDataToReplace, sourceLists) => {
  const multiSetData = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      let excelExportedRow = [];
      for (let key in data[i]) {
        let colIndex = gridProp.excelExportedColumn.findIndex((col) => {
          return col.fieldsKey === key;
        });

        if (colIndex > -1) {
          let exportedObject = {};
          let index = columnsDataToReplace.indexOf(key);
          // Covert to ids to its value
          if (index > -1) {
            const sourceList = sourceLists[index];
            if (key !== "Classification") {
              exportedObject.value = getListItemTextById(sourceList, data[i][key], "text");
            } else {
              exportedObject.value = getListItemTextByValue(sourceList, data[i][key], "text");
            }
            excelExportedRow[colIndex] = exportedObject;
          } else {
            exportedObject.value = String(data[i][key]);
            excelExportedRow[colIndex] = exportedObject;
          }
        }
      }
      // exported Array creation
      multiSetData.push(excelExportedRow);
    }
  } else {
    // Handle condition Exported empty Excel Sheet
    let exportedObject = {};
    let excelExportedRow = [];
    gridProp.excelExportedColumn.map((col) => {
      exportedObject.value = "";
      excelExportedRow.push(exportedObject);
    });
    multiSetData.push(excelExportedRow);
  }

  // Update Key value into Multi Data Set
  let dataSet = [{ columns: gridProp.excelExportedColumn, data: multiSetData }];
  return dataSet;
};

export const XlsxColumns = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

export const downloadExcelFile = (gridProp, data, sheetName, fileName, callback) => {
  let listData = [];
  let headers = [];

  XlsxPopulate.fromBlankAsync().then((workbook) => {
    workbook.sheet(0).name(sheetName);

    workbook.sheet(0).row(1).style({ bold: true });

    if (data.length > 0) {
      data.forEach((item, rowIndex) => {
        let formatItem = {};
        listData[rowIndex] = [];
        gridProp.excelExportedColumn.forEach((column, columnIndex) => {
          if (rowIndex === 0) {
            headers.push(column.title);
          }
          listData[rowIndex][columnIndex] = item[column.fieldsKey];
        });
        listData.push(formatItem);
      });
    } else {
      gridProp.excelExportedColumn.forEach((column, columnIndex) => {
        headers.push(column.title);
      });
    }

    // Set the headers
    const headerRange = workbook.sheet(0).range(`A1:${XlsxColumns[headers.length - 1]}1`);
    headerRange.value([headers]);

    // Set the default column width
    for (let i = 0; i < headers.length; i++) {
      workbook.sheet(0).column(`${XlsxColumns[i]}`).width(20);
    }

    // Set the data
    const dataRange = workbook.sheet(0).range(`A2:${XlsxColumns[headers.length - 1]}${listData.length + 1}`);
    dataRange.value(listData);

    workbook.outputAsync().then((wbOut) => {
      saveAs(new Blob([wbOut], { type: "application/octet-stream" }), `${fileName}.xlsx`);

      callback();
    });
  });
};

// Function to setup the data set to export the data in excel for cosmos
export const setupDataForExcelExportFromCosmos = (gridProp, data) => {
  const multiSetData = [];

  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      let excelExportedRow = [];
      for (let key in data[i]) {
        const dbColumnKey = `${key}`;

        let colIndex = gridProp.excelExportedColumn.findIndex((col) => {
          return col.fieldsKey === dbColumnKey;
        });

        if (colIndex > -1) {
          let val = data[i][key] ? data[i][key] : "";
          excelExportedRow[colIndex] = { value: String(val) };
        }
      }
      // exported Array creation
      multiSetData.push(excelExportedRow);
    }
  } else {
    // Handle condition Exported empty Excel Sheet
    let exportedObject = {};
    let excelExportedRow = [];
    gridProp.excelExportedColumn.map((col) => {
      exportedObject.value = "";
      excelExportedRow.push(exportedObject);
    });
    multiSetData.push(excelExportedRow);
  }

  // Update Key value into Multi Data Set
  let dataSet = [{ columns: gridProp.excelExportedColumn, data: multiSetData }];

  return dataSet;
};

// Return true in case lists are different
export const compareTwoLists = (newValue, prevValue) => {
  if ((newValue && prevValue && newValue.length !== prevValue.length) || (prevValue && prevValue.length > 0 && !newValue) || (!prevValue && newValue && newValue.length > 0)) {
    return true;
  }
  // Iterate through each element
  else if (newValue && prevValue && newValue.length === prevValue.length) {
    let isNewItemAdded = false;
    newValue.sort();
    prevValue.sort();

    for (let i = 0; i < newValue.length; i++) {
      if (newValue[i] !== prevValue[i]) {
        isNewItemAdded = true;
        break;
      }
    }

    return isNewItemAdded;
  }
  return false;
};

// Return true in case lists are different
export const compareTwoObjectLists = (newValue, prevValue, keyToCompare) => {
  if ((newValue && prevValue && newValue.length !== prevValue.length) || (prevValue && prevValue.length > 0 && !newValue) || (!prevValue && newValue && newValue.length > 0)) {
    return true;
  }
  // Iterate through each element
  else if (newValue && prevValue && newValue.length === prevValue.length) {
    let isNewItemAdded = false;
    newValue.sort();
    prevValue.sort();

    for (let i = 0; i < newValue.length; i++) {
      if (newValue[i][keyToCompare] !== prevValue[i][keyToCompare]) {
        isNewItemAdded = true;
        break;
      }
    }

    return isNewItemAdded;
  }
  return false;
};

// Return true in case texts are different
export const compareTwoTextFields = (newValue, prevValue) => {
  if ((newValue && prevValue && newValue.trim() !== prevValue.trim()) || (!newValue && prevValue && prevValue.trim()) || (newValue && newValue.trim() && !prevValue)) {
    return true;
  }

  return false;
};

// Return true in case rich texts are different
export const compareTwoRichTextFields = (newValue, prevValue) => {
  if ((newValue && prevValue && newValue.trim() !== prevValue.trim()) || (newValue && newValue.trim() && newValue !== "<p></p>" && !prevValue)) {
    return true;
  }

  return false;
};

export const allowOnlyAlphabets = (e) => {
  if (!Name_Regex.test(e.target.value)) {
    e.preventDefault();
  }
};

// Function to allow only alpha numeric characters
export const allowOnlyAlphaNumericInput = (e) => {
  if (!AlphaNumeric_Regex.test(e.key)) {
    e.preventDefault();
  }
};

// Function to allow only positive numeric characters
export const allowOnlyPositiveNumericInput = (e) => {
  if (!Number_Regex.test(e.key)) {
    e.preventDefault();
  }
};

export const allowOtherCountryInput = (e, value) => {
  if (!AlphaNumeric_Regex.test(value)) {
    return true;
  }
  return false;
};

export const allowOnlyUSInput = (e, value) => {
  if (!AlphaNumericHypen_Regex.test(value)) {
    return true;
  }
  return false;
};

export const allowOnlyCanadaInput = (e, value) => {
  if (!AlphaNumericWhiteSpace_Regex.test(value)) {
    return true;
  }
  return false;
};

export const allowOnlyNumericInput = (e, val) => {
  if (!Number_Regex.test(val)) {
    e.preventDefault();
  }
};

export const onKeyPostalCode = (e) => {
  if (SPECIAL_CHARACTER_Regex.test(e.key)) {
    e.preventDefault();
    return;
  }
};

export const formatCountryList = (list, placeholder = false) => {
  let result = [];

  if (list && list.length > 0) {
    result = list
      .filter((item) => item.key.toLowerCase() !== "all")
      .map((res) => {
        res.text = `${res.text}`;
        res.value = `${res.value}`;
        return res;
      });
    if (placeholder) {
      result.unshift({ key: "0", text: "Please Select Country", value: "0" });
    } else {
      result.unshift({ key: "0", text: "Select", value: "0" });
    }
    return result;
  }
  return result;
};

export const memberNameFormat = (firstname, middlename, lastname, formatType = "") => {
  lastname = lastname ? lastname.trim() : "";
  firstname = firstname ? firstname.trim() : "";
  middlename = middlename ? middlename.trim().substr(0, 1) : "";
  switch (formatType) {
    case "FL":
      return `${firstname} ${lastname}`;
    case "LF":
      return `${lastname}, ${firstname}`;
    case "LFM":
      return `${lastname}, ${firstname} ${middlename}`;
    case "FML":
      return `${firstname} ${middlename} ${lastname}`;
    default:
      return `${lastname}, ${firstname} ${middlename}`;
  }
};

export const manipulateCountryList = (countryStateData) => {
  const { activeCountryList, rCountryList, usStateList, canadaStateList } = countryStateData;
  if (!isEmpty(activeCountryList)) {
    activeCountryList[0].key = "-1";
    activeCountryList[0].value = "-1";
    activeCountryList[0].text = "Please Select Country";
  }
  if (!isEmpty(rCountryList)) {
    rCountryList[0].key = "-1";
    rCountryList[0].value = "-1";
    rCountryList[0].text = "Please Select Country";
  }
  if (!isEmpty(usStateList)) {
    usStateList[0].key = "-1";
    usStateList[0].value = "-1";
    usStateList[0].text = "Please Select State";
  }
  if (!isEmpty(canadaStateList)) {
    canadaStateList[0].key = "-1";
    canadaStateList[0].value = "-1";
    canadaStateList[0].text = "Please Select State";
  }
  return { activeCountryList, rCountryList, usStateList, canadaStateList };
};

export const getPhoneNumber = (e, phoneNumber, self) => {
  self.setState({ phoneNumber: phoneNumber });
};
export const getExtension = (e, ContactExtension, self) => {
  self.setState({ ContactExtension: ContactExtension });
};
export const onChangePhoneNo = (e, val, self) => {
  if (self.state.maxLen === 10) {
    allowOnlyNumericInput(e, val);
  } else {
    allowOnlyPhoneNumberInput(e, val);
  }
};
export const onCountryChange = (countryName, self) => {
  if ((countryName && countryName.toLowerCase() === CountryCodes.UNITEDSTATES.Name.toLowerCase()) || (countryName && countryName.toLowerCase() === CountryCodes.CANADA.Name.toLowerCase())) {
    self.setState({ isUnitedstatesOrCanadaSelected: true, maxLen: countryName.toLowerCase() === CountryCodes.UNITEDSTATES.Name.toLowerCase() ? 10 : 30 });
    if (countryName && countryName.toLowerCase() === CountryCodes.UNITEDSTATES.Name.toLowerCase()) {
      self.setState({ isUnitedSelected: true });
    } else {
      self.setState({ isUnitedSelected: false });
    }
  } else {
    self.setState({ isUnitedSelected: false, isUnitedstatesOrCanadaSelected: false, maxLen: 30 });
  }
  setStateListData(countryName, self);
  self.props.change("State", "");
  self.props.change("PostalCode", "");
  if (countryName.toLowerCase() === CountryCodes.UNITEDSTATES.Name.toLowerCase() && self.state.phoneNumber && (self.state.phoneNumber.length > 10 || self.state.phoneNumber.match(/^[0-9]*$/) === null)) {
    self.props.change("ContactPhoneNumber", "");
  }
  self.props.change("ContactExtension", "");
};

export const setStateListData = (countryName, self) => {
  let stateListByCountry = [];
  if (countryName === CountryCodes.UNITEDSTATES.Name) {
    stateListByCountry = self.props.usStateList.filter((item) => item.key.toLowerCase() !== "all");
  } else if (countryName === CountryCodes.CANADA.Name) {
    stateListByCountry = self.props.canadaStateList.filter((item) => item.key.toLowerCase() !== "all");
  }
  self.setState({ stateList: stateListByCountry });
};

export const onChangePostalCode = (e, value, self) => {
  if (self.state.isUnitedstatesOrCanadaSelected) {
    if (self.state.isUnitedSelected) {
      if (value !== "") {
        let isValid = allowOnlyUSInput(e, value);
        if (isValid) {
          e.preventDefault();
          return;
        }
      }
      if (value.length > 5) {
        let val = "";
        if (value.indexOf("-") !== 5) {
          value = value.replace(/-/g, "");
        }
        if (!value.includes("-")) {
          val = value.slice(0, 5) + "-" + value.slice(5);
        } else {
          val = value;
        }
        e.preventDefault();
        self.props.change("PostalCode", val);
      } else {
        value = value.replace(/-/g, "");
        e.preventDefault();
        self.props.change("PostalCode", value);
      }
    } else {
      if (value !== "") {
        let isValid = allowOnlyCanadaInput(e, value);
        if (isValid || value.length > 7) {
          e.preventDefault();
          return;
        }
      }
      if (value.length > 3) {
        if (value.indexOf(" ") !== 3) {
          value = value.replace(/ /g, "");
        }
        let val = "";
        if (!value.includes(" ")) {
          val = value.slice(0, 3) + " " + value.slice(3);
        } else {
          val = value;
        }
        e.preventDefault();
        self.props.change("PostalCode", val);
      } else {
        value = value.replace(/ /g, "");
        e.preventDefault();
        self.props.change("PostalCode", value);
      }
    }
  } else {
    let isValid = allowOtherCountryInput(e, value);
    if (isValid || value.length > 9) {
      e.preventDefault();
      return;
    }
  }
};

// Function to allow only positive numeric characters
export const validateOnlyPositiveNumericInput = (val) => {
  return Number_Regex.test(val);
};

export const allowOnlyPhoneNumberInput = (e, val) => {
  if (!PhoneNumber_Regex.test(val)) {
    e.preventDefault();
  }
};

export const formatAuditLogFieldList = (list) => {
  let result = [];
  const newItem = {
    key: 0,
    text: "All",
    value: "All",
  };

  if (list && list.length > 0) {
    list.map((res) => {
      result.push({ key: res.Id, text: `${res.Name}`, value: `${res.Name}` });
    });
  }

  result.unshift(newItem);
  return result;
};

export const getSortOrderFromHeaderClick = (event) => {
  let orderType = 0; // 0 for Ascending order, 1 for descending order

  if (event.target.attributes.getNamedItem("data-order")) {
    orderType = event.target.attributes.getNamedItem("data-order").value;
  } else {
    orderType = event.target.parentElement.attributes.getNamedItem("data-order").value;
  }

  return orderType;
};

export const resetHeaderSortOrder = (list) => {
  list.map((item) => {
    item.orderBy = 0;
    item.orderByIcon = 0;

    if (item.title === "Modified Date/Time") {
      item.orderBy = 0;
      item.orderByIcon = 2;
    } else if (item.sortKey === "WorkItemNumber") {
      item.orderBy = 0;
      item.orderByIcon = 2;
    }
  });
};

export const checkIfDateValid = (date, format) => {
  if (date) {
    let sptArr = date.split("/");
    if (sptArr !== null && sptArr.length === 3) {
      if (sptArr[2] === "0000") {
        return false;
      }
    }
  }

  return moment(date, format, true).isValid();
};

// Returns true if date1 is greater than date2
export const compareDates = (date1, date2, checkOnlyGreaterThan = false, format = "MM/DD/YYYY") => {
  if (checkOnlyGreaterThan) {
    return moment(date1, format, true) > moment(date2, format, true);
  }
  return moment(date1, format, true) >= moment(date2, format, true);
};

export const formatListDataForAutoSearch = (list, column = null) => {
  if (list && list.length > 0) {
    let result = [];
    list.map((item) => {
      result.push({ title: item.Title || item.text || item[column] });
    });

    return result;
  }
  return [];
};

export const validateAuditLogDates = (values, props) => {
  const errors = {};
  let currentDate = formatDateToMMDDYYYY();

  if (values.dateFrom && !checkIfDateValid(values.dateFrom, "MM/DD/YYYY")) {
    errors.dateFrom = getMessage(props.messageCodes, "4003.text");
  } else if (!values.dateTo && values.dateFrom && compareDates(values.dateFrom, currentDate, true)) {
    let errorMessage = getMessage(props.messageCodes, "4016.text").replace("@Date", "From");
    errors.dateFrom = errorMessage;
  }

  if (values.dateTo && !checkIfDateValid(values.dateTo, "MM/DD/YYYY")) {
    errors.dateTo = getMessage(props.messageCodes, "4003.text");
  } else if (values.dateTo && compareDates(values.dateTo, currentDate, true)) {
    let errorMessage = getMessage(props.messageCodes, "4016.text").replace("@Date", "To");
    errors.dateTo = errorMessage;
  }

  if (checkIfDateValid(values.dateFrom, "MM/DD/YYYY") && checkIfDateValid(values.dateTo, "MM/DD/YYYY") && moment(values.dateFrom, "MM/DD/YYYY", true) > moment(values.dateTo, "MM/DD/YYYY", true)) {
    errors.dateTo = getMessage(props.messageCodes, "4002.text");
  }

  return errors;
};

// Private functions
const createChunkFromArray = (arr, chunkSize) => {
  let chunkReturn = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    chunkReturn.push(arr.slice(i, i + chunkSize));
  }
  return chunkReturn;
};

export const randomPassword = (len = 8, minUpper = 0, minLower = 0, minNumber = -1, minSpecial = -1) => {
  let chars = String.fromCharCode(...Array(127).keys()).slice(33), //chars
    A2Z = String.fromCharCode(...Array(91).keys()).slice(65), //A-Z
    a2z = String.fromCharCode(...Array(123).keys()).slice(97), //a-z
    zero2nine = String.fromCharCode(...Array(58).keys()).slice(48), //0-9
    specials = chars.replace(/\w/g, "");
  if (minSpecial < 0) chars = zero2nine + A2Z + a2z;
  if (minNumber < 0) chars = chars.replace(zero2nine, "");
  let minRequired = minSpecial + minUpper + minLower + minNumber;
  let rs = [].concat(
    Array.from({ length: minSpecial ? minSpecial : 0 }, () => specials[Math.floor(Math.random() * specials.length)]),
    Array.from({ length: minUpper ? minUpper : 0 }, () => A2Z[Math.floor(Math.random() * A2Z.length)]),
    Array.from({ length: minLower ? minLower : 0 }, () => a2z[Math.floor(Math.random() * a2z.length)]),
    Array.from({ length: minNumber ? minNumber : 0 }, () => zero2nine[Math.floor(Math.random() * zero2nine.length)]),
    Array.from({ length: Math.max(len, minRequired) - (minRequired ? minRequired : 0) }, () => chars[Math.floor(Math.random() * chars.length)])
  );
  return rs.sort(() => Math.random() > Math.random()).join("");
};

export const generatePassword = (length) => {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const generateUUIDForAutofill = () => {
  let dt = new Date().getTime();
  let uuid = "xxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

// Replace String at Selected Index
export const replaceAt = (string, index, replacement) => {
  return string.substr(0, index) + replacement + string.substr(index + replacement.length);
};

// Validate Params Keys while Url Comes from Request
export const validateUrlParams = (routeUrls, currentPath) => {
  currentPath = currentPath && replaceAt(currentPath, 0, " ");
  currentPath = currentPath && currentPath.trim();
  let routeUrlSplitParamsArray = routeUrls && routeUrls.split("/");
  let currentUrlSplitParamsArray = currentPath && currentPath.split("/");
  if (routeUrls.indexOf("/:") > -1 && currentPath.indexOf(routeUrlSplitParamsArray[0]) > -1 && currentPath.indexOf(routeUrlSplitParamsArray[1]) > -1) {
    for (let i = 0; i < routeUrlSplitParamsArray.length; i++) {
      if (currentPath.indexOf(routeUrlSplitParamsArray[i]) === -1) {
        routeUrlSplitParamsArray[i] = currentUrlSplitParamsArray[i];
      }
    }
    return routeUrlSplitParamsArray && routeUrlSplitParamsArray.join("/");
  }
  return routeUrls;
};

// Validate Request Url For Permission
export const validateNavigationFromUrl = (currentPath) => {
  const beautifyCurrentPath = (url) => url !== "" && url.replace("/", "");
  let navigationModal = NAVIGATION;
  let result = [];
  if (navigationModal && navigationModal.length > 0) {
    for (let i = 0; i < navigationModal.length; i++) {
      let modulesItems = navigationModal[i][MODULES[i]];
      if (modulesItems && modulesItems.length > 0) {
        result = modulesItems.filter((itemsList) => {
          return beautifyCurrentPath(currentPath) === validateUrlParams(itemsList.routeUrl, currentPath);
        });
        if (result && result.length > 0) {
          return { selectedSingleResult: result, selectedFullResult: modulesItems, currentSearchResult: result[0] };
        }
      }
    }
  }
};

// Filter Permission Url if Allow
export const filterPermissionUrl = (requestUrl, method) => {
  let permissions = cacheManager.getItem("privilegeRoleModel") && JSON.parse(cacheManager.getItem("privilegeRoleModel")).length > 0 && get(JSON.parse(cacheManager.getItem("privilegeRoleModel"))[0], "permissions");
  let isPageAuthorized =
    permissions &&
    permissions.length > 0 &&
    permissions.filter((items) => {
      if (method) {
        let hasAccess = false;
        hasAccess = items.methods.indexOf(method) > -1 && requestUrl.oslUrl === items.resource && items.action === "allow";

        // Check for sub routes in case not found in parent
        if (!hasAccess) {
          hasAccess =
            items.subRoutes.length > 0 &&
            items.subRoutes.find((subItem) => {
              if (method) {
                return subItem.methods.indexOf(method) > -1 && requestUrl.oslUrl === subItem.resource && subItem.action === "allow";
              }

              return requestUrl.oslUrl === subItem.resource && subItem.action === "allow";
            });
        }
        return hasAccess;
      }
      return requestUrl.oslUrl === items.resource && items.action === "allow";
    });
  return isPageAuthorized;
};

// Validate Auth or Not
export const allowPermissionForAuth = (isPageAuthorized) => {
  if (isPageAuthorized && isPageAuthorized.length > 0) {
    return true;
  }
  return false;
};

// Set Route Urls
export const routeNavigationUrl = (page, permissionType = null, paramsKey = []) => {
  // Permission Type Could be Null in case of Directly generaing Unique Keys on Page Params;
  let keys = permissionType !== null ? `${PAGE_CONFIG.uniqueKey(page, permissionType)}` : `${page}`; // Generated Keys for Private Routes
  const setParamsOnUrlRoutes = (selectedUrl) => {
    let ParamsIndex = -1;
    let url = selectedUrl && selectedUrl.length > 0 && selectedUrl[0].routeUrl;
    url = url && url.split("/");
    if (url && url.length > 0) {
      for (let i = 0; i < url.length; i++) {
        if (url[i].indexOf(":") > -1) {
          ParamsIndex++;
          url[i] = paramsKey[ParamsIndex];
        }
      }
    }
    return url && url.join("/");
  };
  let moduleUrls = [];
  NAVIGATION.map((moduleItem, index) => moduleItem[MODULES[index]]).map((result) => {
    result.map((items) => items.uniqueKey === keys && moduleUrls.push(items));
  });
  if (moduleUrls && moduleUrls.length > 0) {
    if (paramsKey.length > 0) {
      return `/${setParamsOnUrlRoutes(moduleUrls)}`;
    }
    return `/${moduleUrls[0].routeUrl}`;
  }
  return "/";
};

// Get Current Search Options
export const getSearchCurrentGlobalDropDownOptions = (searchDetails) => {
  // Check Search Option Visible in Child Component
  const { selectedFullResult, currentSearchResult } = searchDetails;
  const validateVisibilityInChildComponent = () => {
    let hasResult = [];
    if (get(currentSearchResult, "hasPageParent") && get(currentSearchResult, "hasPageParent").length > 0) {
      hasResult = selectedFullResult && selectedFullResult.filter((filterResult) => currentSearchResult.hasPageParent.indexOf(filterResult.routeUrl) > -1);
    }
    return hasResult && hasResult.length > 0 && hasResult[0].searchParams && hasResult[0].searchParams.value;
  };
  // Get Search Params
  const { searchParams } = currentSearchResult;
  if (searchParams) {
    return searchParams.value;
  }
  // Validate Search From its Parent
  return validateVisibilityInChildComponent();
};

export const getCurrentSearchPageOptions = (page, privilege) => {
  let searchResult = {};
  let options = PAGE_CONFIG.uniqueKey(page, privilege);
  NAVIGATION.map(
    (moduleItem, index) =>
      moduleItem[MODULES[index]] &&
      moduleItem[MODULES[index]].map((subModuleItems, subIndex) => {
        subModuleItems.uniqueKey === options && Object.assign(searchResult, subModuleItems);
      })
  );
  if (get(searchResult, "searchParams") && get(searchResult.searchParams, "value")) {
    const {
      searchParams: { value },
    } = searchResult;
    return value;
  }
  return null;
};

// Get All Search Options
export const getSearchAllGlobalDropDownOptions = () => {
  let searchResult = [];
  NAVIGATION && NAVIGATION.map((moduleItem, index) => moduleItem[MODULES[index]] && moduleItem[MODULES[index]].map((subModuleItems, subIndex) => allowPermissionForAuth(filterPermissionUrl(subModuleItems)) && subModuleItems.searchParams !== null && searchResult.push(subModuleItems.searchParams)));
  const sortedResult = orderBy(searchResult, ["orderIndex"], ["asc"]);
  return sortedResult;
};

// Get Additional Privilege of Compoenent
export const getModulesAdditionalPrivilege = (selectedUrl) => {
  let additionalPermission = [];
  NAVIGATION && NAVIGATION.map((moduleItem, index) => moduleItem[MODULES[index]] && moduleItem[MODULES[index]].map((subModuleItems, subIndex) => selectedUrl.additionalPermission && selectedUrl.additionalPermission.length > 0 && selectedUrl.additionalPermission.indexOf(subModuleItems.uniqueKey) > -1 && additionalPermission.push(subModuleItems)));
  return additionalPermission;
};

// Validate All User Permission on Page
export const checkUserPermissionOnPage = (allowedPermission, page, permissionType) => {
  // Generated Keys for Private Routes
  let keys = PAGE_CONFIG.uniqueKey(page, permissionType);
  return allowedPermission && get(allowedPermission, [keys]);
};

export const validateEmail = (val) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)) {
    return true;
  }
  return false;
};

export const addRedirectUrlToLogin = (pathName) => {
  return "/login?requestUrl=" + pathName;
};

export const getRedirectUrlAfterLogin = (search) => {
  return extractValueFromUrl(search, "requestUrl");
};

// TODO : fetch azure ad code
export const azureAdFetchCode = () => {
  // Validate location get from params while called
  setCurrentLocationStorage();
  let azureTempleteURL = process.env.REACT_APP_AZURE_ROOT_URL + "/<TENENT_ID>/oauth2/authorize?response_type=code&client_id=<CLIENT_ID>&redirect_uri=<REDIRECT_URL>&state=<STATE>&resource=<RESOURCE>";
  azureTempleteURL = azureTempleteURL.replace("<TENENT_ID>", process.env.REACT_APP_TENANT_ID);
  azureTempleteURL = azureTempleteURL.replace("<CLIENT_ID>", process.env.REACT_APP_AUTH_CLIENT_ID);
  azureTempleteURL = azureTempleteURL.replace("<REDIRECT_URL>", process.env.REACT_APP_REDIRECT_URI);
  azureTempleteURL = azureTempleteURL.replace("<STATE>", `${encrypt(Math.random().toString(36) + ":" + getUserAgent().name)}`);
  azureTempleteURL = azureTempleteURL.replace("<RESOURCE>", process.env.REACT_APP_OSL_AD_AUTH_APP_CLIENT_ID);
  return azureTempleteURL;
};

export const clearAzureAdAuthCodeAction = () => {
  // TODO : Remove forcefully Clean Session.
  cacheManager.clearSession();
  cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
  let url = process.env.REACT_APP_AZURE_LOGOUT_URL + "/" + process.env.REACT_APP_TENANT_ID + "/oauth2/logout?post_logout_redirect_uri=" + process.env.REACT_APP_REDIRECT_URI + "/logout";
  return url;
};

// Navigation Permission Validate
export const validateNavigationPermission = () => {
  const flagFeature = JSON.parse(cacheManager.getItem("featureFlag"));
  const NAVIGATIONRESULT = flagFeature?.DmsAppFeature ? NAVIGATIONHEADER : NAVIGATION;
  let navResult = [];
  // Validate Parent Module Permission
  const validateParentModulePermission = async (parentItem, index) => {
    if (parentItem[MODULES[index]].length > 0) {
      return parentItem[MODULES[index]].filter((result) => allowPermissionForAuth(filterPermissionUrl(result, result.oslMethod)));
    }
    return [];
  };
  // Validate Sub Module Permission
  const validateSubModulePermission = (parentItem, index) => {
    if (parentItem[MODULES[index]].length > 0) {
      parentItem[MODULES[index]].map((result) => {
        if (!allowPermissionForAuth(filterPermissionUrl(result, result.oslMethod))) {
          // Check SubModule Permission
          result.isVisibleOnNavigation = false;
        }
      });
    }
  };
  // Get each navigation values.
  return new Promise((resolve) => {
    if (NAVIGATIONRESULT && NAVIGATIONRESULT.length > 0) {
      navResult = NAVIGATIONRESULT.map((item, index) => {
        let isParentModulePermission = validateParentModulePermission(item, index);
        isParentModulePermission.then((result) => {
          // Check Module Permission
          if (result && result.length === 0) {
            item.isVisibleOnNavigation = false;
          }
        });
        // Check Sub Module Permission
        validateSubModulePermission(item, index);
        return item;
      });
    }
    resolve(navResult);
  });
};

export const setLoginDataToCacheManager = (response, loginActionType = null) => {
  // Perform login Action on Type.
  switch (loginActionType) {
    case "DELETE": // For Delete Token
      cacheManager.removeItem("privilegeRoleModel");
      cacheManager.removeItem("userDetail");
      cacheManager.removeItem("isReactAppAuthorized");
      cacheManager.removeItem("hasDelegationAccess");
      cacheManager.removeItem(cookieKeysEnum.accessToken);
      cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
      break;
    case "LOGIN": // For Add login/Azure Ad Login Request
      cacheManager.removeItem("current-location"); // TODO : Remove current location from storage.
      cacheManager.setItem("privilegeRoleModel", response.data.privilege.privilegeRoleModel);
      cacheManager.setItem("userDetail", response.data.privilege.userDetail);
      cacheManager.setItem("hasDelegationAccess", response.data.privilege.hasDelegationAccess);
      cacheManager.setItem("isReactAppAuthorized", true);
      cacheManager.setItem(cookieKeysEnum.accessToken, response.data.accessToken);
      break;
    case "REFRESH": // For Refresh token Request Update
      if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true") {
        if (cacheManager.getItem(cookieKeysEnum.accessToken)) {
          cacheManager.removeItem(cookieKeysEnum.accessToken);
        }
        cacheManager.setItem(cookieKeysEnum.accessToken, response.data.accessToken);
      }
      break;
    default:
      break;
  }
};

export const isReactAppAuthorized = () => {
  if (cacheManager.getItem("isReactAppAuthorized")) {
    // VALIDATE THE TOEKN EXIST OR NOT
    if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true" && !isTokenExistInCookies()) {
      cacheManager.clearSession(); // Check Access Token Should be Exist In Case of Azure AD
    }
    return true;
  }
  return false;
};

export const getCurrentUserEmail = () => {
  let userDetail = cacheManager.getItem("userDetail") && JSON.parse(cacheManager.getItem("userDetail"));
  if (userDetail !== null) {
    // GET CURRENT LOGGED IN USER EMAIL.
    return get(userDetail, "email");
  }
};

export const isTokenExistInCookies = () => {
  if (cookiesManager.getCookie(cookieKeysEnum["APP_SESSIONID"])) {
    return true;
  }
  return false;
};

export const getDomainFromSubDomain = (hostname) => {
  if (window.location.hostname !== "localhost") {
    const dotIndex = hostname.indexOf(".");
    return hostname.substring(dotIndex + 1);
  }
  return hostname;
};

export const noCookiesRedirectToLogin = () => {
  if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true" && !isTokenExistInCookies()) {
    window.location.assign("/");
  }
};

// Method to remove the trailing and ending spaces and empty <br> tags
export const removeExtraSpaces = (inputHTML) => {
  if (inputHTML && inputHTML.length > 0) {
    let hasFoundEmptySpace = false;
    let inputLength = 0;

    do {
      hasFoundEmptySpace = false;

      // Remove the spaces from start
      if (inputHTML.indexOf("&nbsp;") === 0) {
        inputHTML = inputHTML.replace("&nbsp;", "");
        hasFoundEmptySpace = true;
      }

      // Remove the spaces from end
      inputLength = inputHTML.length;
      if (inputHTML.lastIndexOf("&nbsp;") >= 0 && inputHTML.lastIndexOf("&nbsp;") === inputLength - 6) {
        inputHTML = inputHTML.substring(0, inputLength - 6);
        hasFoundEmptySpace = true;
      }

      inputHTML = inputHTML.trim();
    } while (hasFoundEmptySpace);

    // Remove the div with empty <br> tags
    const divElement = document.createElement("div");
    divElement.innerHTML = inputHTML;
    let divs = divElement.querySelectorAll("div");
    let startIndex = -1;
    let endIndex = -1;

    // Identify which divs we need to remove.
    if (divs.length > 0) {
      divs.forEach((div, index) => {
        if (div.innerText.trim() === "") {
          if (index === 0 && divElement.innerHTML.indexOf("<div>") === 0) {
            startIndex++;
          } else if (index > 0 && index === startIndex + 1) {
            startIndex++;
          }
        } else {
          endIndex = index + 1;
        }
      });
    }

    if (startIndex > -1) {
      for (let i = 0; i <= startIndex; i++) {
        divs[i].remove();
      }
    }
    if (endIndex > -1 && endIndex < divs.length) {
      for (let i = endIndex; i < divs.length; i++) {
        divs[i].remove();
      }
    }

    // Remove the ending spaces from the last div element
    divs = divElement.querySelectorAll("div");
    if (divs.length > 0) {
      let endDiv = divs[divs.length - 1];
      let divHtml = endDiv.innerHTML.trim();

      if (divHtml.lastIndexOf("&nbsp;") > -1 && divHtml.lastIndexOf("&nbsp;") === divHtml.length - 6) {
        endDiv.remove();

        hasFoundEmptySpace = false;

        do {
          hasFoundEmptySpace = false;

          if (divHtml.lastIndexOf("&nbsp;") > -1 && divHtml.lastIndexOf("&nbsp;") === divHtml.length - 6) {
            divHtml = divHtml.substring(0, divHtml.length - 6);
            hasFoundEmptySpace = true;
          }
          divHtml = divHtml.trim();
        } while (hasFoundEmptySpace);

        const newDiv = document.createElement("div");
        newDiv.innerHTML = divHtml;

        divElement.appendChild(newDiv);
      }
    }

    return divElement.innerHTML;
  }

  return inputHTML.trim();
};

export const downloadFile = (docPath, fileUrl) => {
  const link = document.createElement("a");

  const fileNameSplit = docPath ? docPath.split("/") : [];
  if (fileNameSplit.length > 0) {
    link.download = fileNameSplit[fileNameSplit.length - 1];
  }
  let type = docPath.split(".").pop() || "";
  if (type.toLowerCase() === "pdf") {
    link.target = "_blank";
  }

  // Construct the URI
  link.href = fileUrl;
  document.body.appendChild(link);
  link.click();

  // Cleanup the DOM
  document.body.removeChild(link);
};

export const callAPI = (url, verb, data, callback) => {
  axios[verb](url, data)
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      callback({});
    });
};

// TODO : Check string whether string encoded or not.
export const isEncoded = (str) => {
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
};

let prevOrgName = "";
let TIMEOUT = null;

export const handleOnChangeOrgName = (self, value, isCommonOrg = false, isInputTagAutoSearch = false, minLengthToCallAPI = 2) => {
  value = value.toLowerCase();
  const trimmedValue = value.trim();
  self.setState({ addressList: [], filteredAddressList: [] });

  if (trimmedValue.length >= minLengthToCallAPI) {
    if (trimmedValue === prevOrgName.trim()) {
      return;
    }

    clearTimeout(TIMEOUT);

    self.setState({ isOrgLoading: true });

    TIMEOUT = setTimeout(() => {
      // Check if we need to pull data from API rather than filtering the existing list
      if (prevOrgName.trim() !== "" && trimmedValue !== "" && value.length > prevOrgName.length && value.indexOf(prevOrgName) === 0 && self.state.organizationList.length > 0) {
        let organizationList = [];
        organizationList = self.state.organizationList.filter((item) => {
          if (isInputTagAutoSearch) {
            return item.value.toLowerCase().indexOf(trimmedValue.toLowerCase()) >= 0;
          }
          return item.title.toLowerCase().indexOf(trimmedValue.toLowerCase()) >= 0;
        });
        self.setState({
          organizationList,
          isOrgLoading: false,
        });
      } else if (trimmedValue.length > 0) {
        self.props.getOrganizationList(trimmedValue, (response) => {
          let startWith = [];
          let others = [];
          forEach(response.OrgList, (company) => {
            const name = company.CompanyName.toLowerCase();
            if (name.indexOf(trimmedValue.toLowerCase()) === 0) {
              startWith.push(company);
            } else {
              others.push(company);
            }
          });
          const modifiedList = startWith.concat(others);
          if (isCommonOrg) {
            let addressList = formatAddressLineList(response.AddressList);
            self.setState({
              isOrgLoading: false,
              organizationList: formatListDataForAutoSearch(modifiedList, "CompanyName"),
              addressList,
              filteredAddressList: self.state.isAddressFieldEmpty ? addressList : [],
            });
          } else {
            self.setState({
              isOrgLoading: false,
              organizationList: isInputTagAutoSearch ? formatList(modifiedList, self) : formatListDataForAutoSearch(modifiedList, "CompanyName"),
            });
          }
        });
      } else {
        self.setState({ isOrgLoading: false, organizationList: [] });
      }

      prevOrgName = value;
    }, 300);
  } else {
    prevOrgName = value;
    self.setState({ isOrgLoading: false, organizationList: [] });
  }
};

export const formatAddressLineList = (list) => {
  if (list && list.length > 0) {
    list.map((item) => {
      let fullAddress = item.AddressLine1;
      if (item.AddressLine2) {
        fullAddress += `, ${item.AddressLine2}`;
      }
      if (item.City) {
        fullAddress += `, ${item.City}`;
      }
      if (item.StateName) {
        fullAddress += `, ${item.StateName}`;
      }
      if (item.PostalCode) {
        fullAddress += `, ${item.PostalCode}`;
      }
      if (item.CountryCode) {
        fullAddress += `, ${item.CountryCode}`;
      }
      item.FullAddress = fullAddress;
      item.title = fullAddress;
    });

    return list;
  }

  return [];
};

const formatList = (list, self) => {
  let result = [];

  if (list && list.length > 0) {
    list.map((item) => {
      if (self.state.tags.map((i) => i.toLowerCase()).indexOf(item.CompanyName.toLowerCase()) === -1) {
        result.push({ key: item.CompanyName, text: item.CompanyName, value: item.CompanyName });
      }
    });
  }

  return result;
};

export const getFormData = (value, self) => {
  let formData = {
    formLabels: {},
    countryStateData: self.state && self.state.countryStateData ? self.state.countryStateData : self.props.countryStateData ? self.props.countryStateData : "",
  };

  switch (value) {
    case 0:
      formData.formLabels = {
        orgLabel: "Organization Name",
        orgHint: "Please try to search using full name instead of acronym/abbreviations.",
        addressLine1Label: "Organization Address Line 1",
      };
      break;
    case 1:
      formData.formLabels = {
        orgLabel: "Organization Name",
        orgHint: "Please do not use abbreviations, e.g. “Johnson & Johnson” not “J&J",
        addressLine1Label: "Organization Address Line 1",
      };
      break;
    case 2:
      formData.formLabels = {
        orgLabel: "Organization Name",
        orgHint: "",
        addressLine1Label: "Organization Address Line 1",
      };
      break;
    case 3:
      formData.formLabels = {
        orgLabel: "Name of Consumer Advocacy Group(s)",
        orgHint: "Please do not use abbreviations, e.g. “National Association of Consumer Advocates” not “NACA”",
        addressLine1Label: "Organization Address Line 1",
      };
      break;
    case 4:
      formData.formLabels = {
        orgLabel: "Name of your Consulting Firm",
        orgHint: "",
        addressLine1Label: "Organization Address Line 1",
      };
      break;
    case 5:
      formData.formLabels = {
        orgLabel: "Name of your College/University",
        orgHint: "Please do not use abbreviations, e.g. “Pennsylvania State University” not “PSU”",
        addressLine1Label: "College/University Address Line 1",
      };
      break;
    case 6:
      formData.formLabels = {
        orgLabel: "Government Agency Name",
        orgHint: "Please do not use abbreviations, e.g. “Environmental Protection Agency” not “EPA”",
        addressLine1Label: "Agency Address Line 1",
      };
      break;
    case 7:
      formData.formLabels = {
        orgLabel: "Organization Name",
        orgHint: "Please do not use abbreviations, e.g. “Johnson & Johnson” not “J&J",
        addressLine1Label: "Organizational Address Line 1",
      };
      break;
    default:
      break;
  }
  return formData;
};

export const formatEventList = (eventList) => {
  let result = [];
  if (eventList !== null && eventList.length > 0) {
    result = eventList.map((res) => {
      res.key = res.EventId;
      res.text = `${res.EventName}`;
      res.value = res.EventId;
      return res;
    });
  }
  return result;
};

export const formatEventCommitteeList = (eventCommitteeList) => {
  let transformed = [];
  transformed = eventCommitteeList && eventCommitteeList.length > 0 && eventCommitteeList.map(({ CommitteeId, CommitteeDesignation, Title }) => ({ key: CommitteeId, value: CommitteeId, text: CommitteeDesignation + " - " + Title, CommitteeDesignation }));
  return eventCommitteeList.length > 0 ? [{ key: "", value: "", text: "Select" }, ...transformed] : [{ key: "", value: "", text: "Select" }];
};

export const formatEventRegistrationFeesCommitteeList = (eventCommitteeList) => {
  let transformed = [];
  transformed = eventCommitteeList && eventCommitteeList.length > 0 && eventCommitteeList.map(({ CommitteeId, CommitteeDesignation, Title }) => ({ key: CommitteeId, value: CommitteeId, text: CommitteeDesignation + (Title ? " - " : "") + Title, CommitteeDesignation }));

  if (transformed !== null && transformed.length > 0) {
    return [{ key: 0, value: 0, text: "All" }, ...transformed];
  }
  return [];
};

export const resetStateMemberForm = (self) => {
  self.props.change("manualOrderOrggNam", "");
  self.props.change("manualOrderOrgAdrLine1", "");
  self.props.change("organizationName", "");
  self.props.change("organizationalName", "");
  self.props.change("orgAddressLine1", "");
  self.props.change("manualOrderOrgAdrLine2", "");
  self.props.change("manualOrderOrgCty", "");
  self.props.change("orgCountry", "");
  self.props.change("manualOrderstatpro", "");
  self.props.change("manualOrderZpcd", "");
  self.props.change("divisionName", "");
  self.props.change("manualOrderparentOrg", "");
  self.props.change("orgnizationWebsite", "");
  self.props.change("describeProducts", "");

  // Clear state for Consultant Details Form
  self.props.change("consultingFirmName", "");
  self.props.change("bestDescribesConsultingFirm", "");
  self.props.change("organizationNameConsultantDetails", "");
  self.props.change("organizationWebsiteConsultantDetails", "");
  self.props.change("organizationPrimarilyProduceProducts", "");
  self.props.change("anyOrganizationPrimarilyProduceProducts", "");
  self.props.change("productSells", "");
  self.props.change("businessActivity", "");
  self.props.change("organizationNameYouRepresenting", "");
  self.props.change("organizationNameYouRepresent", "");

  // Clear state for Government Agency Details form
  self.props.change("governmentAgencyName", "");
  self.props.change("branchOfficeDivision", "");
  self.props.change("websiteAddressAgency", "");
  self.props.change("natureOfInterest", "");

  // Clear state for Academia Details form
  self.props.change("universityCollegeName", "");
  self.props.change("department", "");
  self.props.change("universityCollegeWebsite", "");
  self.props.change("natureOfInterestAcademia", "");

  // Clear state for Consumer Details form
  self.props.change("consumerAdvocacyGroupBelong", "");
  self.props.change("consumerAdvocacyGroupName", "");
  self.props.change("advocacyGroupEmail", "");
  self.props.change("natureOfInterestConsumerDetails", "");

  // Clear state for Consumer Advocacy Organization
  self.props.change("consumerAdvocacyGroupNameOrganizational", "");
  self.props.change("natureOfInterestConsumerDetailsOrganizational", "");

  // Clear state for Other Primary Activity form
  self.props.change("OtherAffiliation", "");
  self.props.change("OtherInterestNature", "");
};
export const DropdownFormatting = (list, listName) => {
  let result = [];

  if (list !== null && list.length > 0) {
    switch (listName) {
      case "mainCommitteeDropdown":
        result = list.map((res) => {
          res.key = res.CommitteeId;
          res.text = `${res.CommitteeDesignation}`;
          res.value = res.CommitteeId;
          return res;
        });
        break;

      case "primaryActivityDropdown":
        result = list.map((res) => {
          res.key = res.CommitteePrimaryActivityId;
          res.text = `${res.ActivityName}`;
          res.value = res.CommitteePrimaryActivityId;
          return res;
        });
        break;

      case "subCommitteeDropdown":
        result = list.map((res) => {
          res.key = res.SubCommitteeId;
          res.text = `${res.CommitteeDesignation}`;
          res.value = res.SubCommitteeId;
          return res;
        });
        break;
      case "committeeOfInterest":
        result = list.map((res) => {
          res.key = res.CommitteeId;
          res.text = `${res.CommitteeDesignation}`;
          res.value = res.CommitteeId;
          return res;
        });
        break;

      default:
        return false;
    }
  }
  ["subCommitteeDropdown", "committeeOfInterest"].indexOf(listName) === -1 && result.unshift({ key: -1, text: "Select", value: -1 });
  return result;
};

export const IsValidZipCode = (zip) => {
  let isValid = /^[0-9]{5}(?:-[0-9]{4})?$/.test(zip);
  if (isValid) {
    return true;
  }
  return false;
};

export const formatTime = (time) => moment(time, ["HH:mm"]).format("hh:mm A");

export const getMinutes = (cTime) => moment(cTime, "HH:mm:ss a").get("minutes");

// TODO : Set Number format for fee amount.
export const memberFeeNumberFormat = (value) => {
  if (value && value > 0) {
    value = value.toString();
    let zeroFound = value.split("0");
    if (zeroFound[0] !== 0 && value.length === 1) {
      return `0${value}`;
    } else if (zeroFound[0] === 0 && value.length === 2) {
      return `${value}`;
    }
    return Number.isInteger(Number(value)) ? Number(value).toFixed(2) : typeof value === "string" ? Number(value).toFixed(2) : value.toFixed(2);
  }
  return Number(value).toFixed(2);
};

// TODO : Download CSV file sing blob type.
export const exportCSVFile = (filename, rows) => {
  const processRow = (row) => {
    let finalVal = "";
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) {
        result = '"' + result + '"';
      }
      if (j > 0) {
        finalVal += ",";
      }
      finalVal += result;
    }
    return finalVal + "\n";
  };

  let csvFile = "";
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  let blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, `${filename}.csv`);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${filename}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const handleAutoComplete = (value) => {
  const none_value = `none_${generateUUIDForAutofill()}`;
  let el = document.querySelectorAll("div.autocompleteOff input[type='text']");
  if (el && el.length > 0) {
    el.forEach(function (element) {
      element.setAttribute("autocomplete", value ? value : none_value);
    });
  }
};

export const getDateWithFormat = (format = "YYYY-MM-DD") => moment().format(format);

export const isLocalhost = () => {
  return window.location.origin.includes("localhost");
};

// TODO : Get User Agitent (Identify request coming from browser.)
export const getUserAgent = () => {
  const test = (regexp) => regexp.test(window.navigator.userAgent);
  switch (true) {
    case test(/edg/i):
      return { name: "Edge", version: "" };
    case test(/trident/i):
      return { name: "IE", version: "" };
    case test(/firefox|fxios/i):
      return { name: "Firefox", version: "" };
    case test(/opr\//i):
      return { name: "Opera", version: "" };
    case test(/ucbrowser/i):
      return { name: "UC", version: "" };
    case test(/samsungbrowser/i):
      return { name: "Samsung", version: "" };
    case test(/chrome|chromium|crios/i):
      return { name: "Chrome", version: "" };
    case test(/safari/i):
      return { name: "Safari", version: "" };
    default:
      return { name: "Other", version: "" };
  }
};

export const hideViewPermissionForSubModulePrivilege = (SubModulePrivilegeId) => {
  return disableViewSubModulePrivilegeId.indexOf(SubModulePrivilegeId) > -1;
};

export const monthsList = [{ label: "Jan" }, { label: "Feb" }, { label: "March" }, { label: "April" }, { label: "May" }, { label: "June" }, { label: "July" }, { label: "Aug" }, { label: "Sept" }, { label: "Oct" }, { label: "Nov" }, { label: "Dec" }];

export const commaSeperator = (value) => {
  // return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ;
  return new Intl.NumberFormat("en-US").format(value);
};

export const encodeStreamData = (data) => {
  const str =
    data &&
    data.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
  return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
};
export const downloadByUrl = (filename, url) => {
  fetch(url).then(function (t) {
    return t.blob().then((b) => {
      var a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", filename);
      a.click();
    });
  });
};

// Generating year list
export const generateYearList = (yearsBack = 3, includeCurrentYear = true) => {
  const currentYear = new Date().getFullYear();

  let yearOptionsList = [];
  let key = 0;
  const toYear = currentYear - yearsBack;
  const fromYear = includeCurrentYear ? currentYear : currentYear - 1;
  for (let y = fromYear; y >= toYear; y--) {
    yearOptionsList.push({
      key: key,
      text: y,
      value: y,
    });
    key++;
  }
  return yearOptionsList;
};

export const setMinDate = () => {
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 3, 0, 1);
  return minDate;
};

// Get previous month with date 01
export const getDateWithPreviousMonth = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  date.setDate(1);
  return date;
};

export const downloadDocument = (fileDataResponse, fileName) => {
  const arr = fileDataResponse.content.Body.data;
  const byteArray = new Uint8Array(arr);

  const a = window.document.createElement("a");
  a.href = window.URL.createObjectURL(new Blob([byteArray], { type: fileDataResponse.content.ContentType }));
  a.download = fileName || "";

  // Append anchor to body.
  document.body.appendChild(a);
  a.click();

  // Remove anchor from body
  document.body.removeChild(a);
};

export const downloadFileByBase64 = (fileContent, fileName) => {
  let a = window.document.createElement("a");
  a.href = "data:application/octet-stream;base64," + fileContent;
  a.download = fileName || "";
  // Append anchor to body.
  document.body.appendChild(a);
  a.click();
  // Remove anchor from body
  document.body.removeChild(a);
};

//get formatted date without slash
export const formatDateWithoutSlash = (date, dateFormat = "MMDDYYYY") => {
  return moment(date).format(dateFormat);
};

// TODO : enum for committee levels
export const COMMITTEE_LEVELS = {
  MAINCOMMITTEE: 1,
  SUBCOMMITTEE: 2,
  SECTION: 3,
};

export const MEMBERSHIP_REQUEST_TYPE = {
  NEWMEMBER: "New Member",
  RENEW: "Renewal",
  REINSTATE: "Reinstate",
  STUDENT_UPGRADE: "Student Upgrade",
};

export const EMAIL_TEMPLATE_NAME = {
  CHANGE_OF_EMPLOYMENT: "Change_Of_Employment_Email",
  CHANGE_OF_COMPANY_ADDRESS_EMAIL: "Change_Of_Company_Address_Email",
};

export const FORM_TYPE_ORG_UPDATE = {
  Member: "Member",
  Organization: "Organization",
};

export const BOOK_VOLUME_MOVEMENT_TEMPLATE = "annual-renewal/BookVolumeMovementTemplate.xlsx";

export const canadaZipCodeFix = (str) => {
  if (str.length > 0 && str.includes("-")) return str.replace("-", " ");
  return str;
};

export const searchEventList = (self, trimmedValue) => {
  let { eventList } = self.state;
  let eventSearchList =
    eventList && eventList.length > 0
      ? eventList.filter((item) => {
          return (item.EventName && item.EventName.indexOf(trimmedValue) >= 0) || (item.EventName && item.EventName.toLowerCase().indexOf(trimmedValue.toLowerCase()) >= 0);
        })
      : [];
  if (eventSearchList.length > 0 && trimmedValue.length > 0) {
    self.setState({ isLoading: false, eventSearchList, isOpen: true, showNoResultsMessage: eventSearchList.length === 0 });
  } else if (!trimmedValue) {
    self.setState({ eventSearchList: self.state.eventList }, () => {
      let { eventSearchList } = self.state;
      let showNoResultsMessage = eventSearchList.length > 0 ? false : true;
      self.setState({ isLoading: false, eventSearchList, isOpen: true, showNoResultsMessage: showNoResultsMessage });
    });
  } else if (eventSearchList.length === 0 && trimmedValue.length > 0) {
    self.setState({ isLoading: false, eventSearchList: [], isOpen: true, showNoResultsMessage: eventSearchList.length === 0 });
  } else {
    self.setState({ isLoading: false, eventSearchList: [], isOpen: false });
  }
};

export const openApiPathArray = ["/master/featureflag"];

export const getAddedDaysDate = (days, format = "MM/DD/YYYY") => {
  const now = new Date();
  return moment(now).add(days, "days").format(format);
};

export const convertImageUrlToBase64 = async (imageUrl) => {
  const fechedImage = await fetch(imageUrl);
  const blob = await fechedImage.blob();
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
      reader.onerror = () => {
          return reject(this);
      };
      reader.readAsDataURL(blob);
  })
}

export default {
  Password_Regex,
  Email_Regex,
  IP_Regex,
  File_Regex,
  Blank_Space_Check_Regex,
  AlphaNumeric_Regex,
  AlphaNumericWhiteSpace_Regex,
  Name_Regex,
  PositiveRegex,
  NegativeRegex,
  Number_Regex,
  getFormData,
  getCookie,
  logMessage,
  nullCheck,
  monthYearDateMask,
  dateMasking,
  phoneNumberMask,
  getDateValue,
  getUrlToRedirect,
  getLoggedInUserId,
  deepCopy,
  getFileNameWithoutType,
  getFileType,
  getFilteredUsersList,
  toCurrency,
  toMMMMYYYY,
  capitalize,
  toGMapQuery,
  toUpperMagnitude,
  formatDateToYYYYMMDD,
  formatDateToMMDDYYYY,
  formatDateToMMYYYY,
  formatDateToYYYY,
  formatDateToMMDDYYYYHHMMSS,
  formatDateToM,
  getStartDateOfCurrentMonth,
  getCurrentlyShowingItemsInGrid,
  scrollToTopOfPage,
  utcDateTimeToLocalDateTime,
  utcDateTimeSecondToLocalDateTime,
  getListItemTextById,
  getListItemTextByValue,
  copyDataToClipboard,
  checkIfUserDidAdvanceSearch,
  exportFileName,
  replaceUrlParam,
  extractValueFromUrl,
  formatUserListForCommunicationLog,
  extractUserIdsFromComment,
  getMessage,
  loadScriptTag,
  removeScript,
  trimRichText,
  getHighlightedHTMLElement,
  exportWordFile,
  getUrlForExportData,
  updateGridProps,
  validateExportableFields,
  downloadExcelFile,
  setupDataForExcelExport,
  setupDataForExcelExportFromCosmos,
  compareTwoLists,
  compareTwoObjectLists,
  compareTwoTextFields,
  compareTwoRichTextFields,
  allowOnlyAlphabets,
  allowOnlyAlphaNumericInput,
  allowOnlyPositiveNumericInput,
  allowOnlyPhoneNumberInput,
  validateOnlyPositiveNumericInput,
  formatAuditLogFieldList,
  getSortOrderFromHeaderClick,
  resetHeaderSortOrder,
  checkIfDateValid,
  compareDates,
  formatListDataForAutoSearch,
  validateAuditLogDates,
  randomPassword,
  anchorTextLengthValidate,
  generatePassword,
  generateUUID,
  generateUUIDForAutofill,
  validateNavigationFromUrl,
  filterPermissionUrl,
  allowPermissionForAuth,
  routeNavigationUrl,
  getSearchCurrentGlobalDropDownOptions,
  getSearchAllGlobalDropDownOptions,
  getModulesAdditionalPrivilege,
  validateEmail,
  addRedirectUrlToLogin,
  getRedirectUrlAfterLogin,
  getCurrentSearchPageOptions,
  toDateWithTimeLocal,
  azureAdFetchCode,
  validateNavigationPermission,
  setLoginDataToCacheManager,
  isReactAppAuthorized,
  clearAzureAdAuthCodeAction,
  getCurrentUserEmail,
  cookiesManager,
  isTokenExistInCookies,
  noCookiesRedirectToLogin,
  removeExtraSpaces,
  isEncoded,
  onKeyPostalCode,
  onChangePostalCode,
  formatCountryList,
  memberNameFormat,
  manipulateCountryList,
  setStateListData,
  getPhoneNumber,
  handleOnChangeOrgName,
  formatAddressLineList,
  resetStateMemberForm,
  IsValidZipCode,
  formatEventList,
  formatEventCommitteeList,
  formatDate,
  getMinutes,
  memberFeeNumberFormat,
  exportCSVFile,
  handleAutoComplete,
  getDateWithFormat,
  toUTCDateAndTime,
  getUserAgent,
  monthsList,
  commaSeperator,
  XlsxColumns,
  encodeStreamData,
  downloadByUrl,
  formatMonthToNum,
  downloadDocument,
  downloadFileByBase64,
  formatDateWithoutSlash,
  searchEventList,
  openApiPathArray,
  getAddedDaysDate,
};
